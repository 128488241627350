.Navbar{
  text-transform: uppercase;
  .Desktop-navbar{
    box-shadow: 1px 1px 10px #212529;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
    background-color: white;

    ul{
      list-style-type: none;
      margin: 0;
      display: flex;
      li{
        margin-right: 15px;
        margin-left: 15px;
        a{
          font-size: 18px;
          color: #212529;
          text-decoration: none;
          padding: 3px 0;
          &:after{
            content: "";
            width: 100%;
            height: 2px;
            position: absolute;
            bottom: 0;
            left: 0;
            background-color: transparent;
            transition: 0.3s all linear;
          }
        }
        .active{
          position: relative;
          font-weight: bold;
          &:after{
            background-color: #212529;
          }
        }
      }
    }

    .login-user {
      position: relative;

      &-logo {
        background-color: white;
        height: 33px;
        width: 33px;
        border-radius: 50%;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        -o-border-radius: 50%;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        cursor: pointer;
        overflow: hidden;

        svg {
          font-size: 26px;
          color: var(--gray-color);
        }
      }

      &-link {
        text-decoration: none;
        color: #212529;
        font-size: 16px;
        font-weight: bold;
        padding: 10px;
        border-radius: 5px;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        -ms-border-radius: 5px;
        -o-border-radius: 5px;
        background-color: white;
        display: flex;
        align-items: center;
        gap: 5px;
        cursor: pointer;
      }

      &-content {
        position: absolute;
        top: 120%;
        right: 0;
        width: fit-content;
        height: fit-content;
        background-color: #212529;
        padding: 5px;
        box-shadow: #212529;
        display: flex;
        flex-direction: column;
        gap: 10px;
        border-radius: 5px;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        -ms-border-radius: 5px;
        -o-border-radius: 5px;
        transition: all .3s ease;
        -webkit-transition: all .3s ease;
        -moz-transition: all .3s ease;
        -ms-transition: all .3s ease;
        -o-transition: all .3s ease;
        clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
      }

      &-content.active {
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
      }

    }

  }

  .Mobile-navbar{
    display: none;
  }

  .mobile-menu{
    display: none;
  }
}

@media (max-width: 576px) {

  .Navbar{

    .Desktop-navbar{
      display: none;
    }

    .Mobile-navbar{
      padding: 10px 0;
      display: block;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 1000;
      background-color: white;
      box-shadow: 1px 1px 10px #212529;

      .login-user {
        position: relative;
        margin-bottom: 5px;
        &-logo {
          background-color: white;
          height: 33px;
          width: 33px;
          border-radius: 50%;
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          -ms-border-radius: 50%;
          -o-border-radius: 50%;
          display: flex;
          align-items: flex-end;
          justify-content: center;
          cursor: pointer;
          overflow: hidden;

          svg {
            font-size: 26px;
            color: var(--gray-color);
          }
        }
        &-link {
          text-decoration: none;
          color: #212529;
          font-size: 16px;
          font-weight: bold;
          padding: 10px;
          border-radius: 5px;
          -webkit-border-radius: 5px;
          -moz-border-radius: 5px;
          -ms-border-radius: 5px;
          -o-border-radius: 5px;
          background-color: white;
          display: flex;
          align-items: center;
          gap: 5px;
          cursor: pointer;
        }

        &-content {
          position: absolute;
          top: 120%;
          right: 0;
          width: fit-content;
          height: fit-content;
          background-color: #212529;
          padding: 5px;
          box-shadow: #212529;
          display: flex;
          flex-direction: column;
          gap: 10px;
          border-radius: 5px;
          -webkit-border-radius: 5px;
          -moz-border-radius: 5px;
          -ms-border-radius: 5px;
          -o-border-radius: 5px;
          transition: all .3s ease;
          -webkit-transition: all .3s ease;
          -moz-transition: all .3s ease;
          -ms-transition: all .3s ease;
          -o-transition: all .3s ease;
          clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
        }

        &-content.active {
          clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
        }

      }

      .menu-btn {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 55px;
        height: 44px;
        cursor: pointer;
        transition: all .5s ease-in-out;
        border-radius: 6px;
      }
      .menu-btn__burger {
        width: 35px;
        height: 4px;
        background-color: #212529;
        border-radius: 5px;
        transition: all .5s ease-in-out;
      }
      .menu-btn__burger::before,
      .menu-btn__burger::after {
        content: '';
        position: absolute;
        width: 35px;
        height: 4px;
        background: #212529;
        border-radius: 5px;
        transition: all .5s ease-in-out;
      }
      .menu-btn__burger::before {
        transform: translateY(-9px);
      }
      .menu-btn__burger::after {
        transform: translateY(9px);
      }
      /* ANIMATION */
      .menu-btn.open .menu-btn__burger {
        transform: translateX(-50px);
        background: transparent;
        box-shadow: none;
      }
      .menu-btn.open .menu-btn__burger::before {
        transform: rotate(45deg) translate(35px, -35px);
      }
      .menu-btn.open .menu-btn__burger::after {
        transform: rotate(-45deg) translate(35px, 35px);
      }

    }

    .mobile-menu{
      position: fixed;
      height: calc(100vh - 62px);
      top: 62px;
      background-color: #212529;
      width: 100%;
      transition: 0.3s all linear;
      z-index: 100;
      display: flex;
      align-items: center;
      justify-content: center;
      ul{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0;
        li{
          margin: 15px 0;
          a{
            font-size: 30px;
            text-decoration: none;
            color: white;
            font-weight: bold;
            padding: 3px 0;
            &:after{
              content: "";
              width: 100%;
              height: 3px;
              position: absolute;
              bottom: 0;
              left: 0;
              background-color: transparent;
              transition: 0.3s all linear;
            }
          }
          .active{
            position: relative;
            font-weight: bold;
            &:after{
              background-color: white;
            }
          }
        }
      }
    }

    .show-menu{
      left: 0;
    }

    .close-menu{
      left: -100%;
    }
  }

}