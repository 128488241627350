.Login{
  .form-label{
    position: relative;
    svg{
      position: absolute;
      right: 10px !important;
      bottom: 5px !important;
    }
    input{
      border-width: 2px;
      &:focus{
        box-shadow: none;
        border: 2px solid #212529;
      }
    }
  }
  button{
    position: relative;
    .spinner-border{
      position: absolute !important;
      left: 60px !important;
      top: 10px !important;
    }
  }
}