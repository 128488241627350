@font-face {
  font-family: "Youtube";
  src: url("../src/font/Youtube/youtube-sans-light.ttf") format('truetype');
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

input, select{
  border-width: 2px !important;
  &:focus{
    box-shadow: none !important;
    border: 2px solid #212529 !important;
  }
}

table{
  thead{
    text-transform: uppercase;
  }
}

body {

  font-family: 'Youtube', sans-serif !important;

  &::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #E8EAED;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #212529;
    border-radius: 1px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #424951;
  }
};

.modal-content{

  .close{
    border: none;
    background-color: transparent;
    span{
      content: url("./assets/images/close.png");
      width: 20px;
    }
  }

  form{
    label{
      font-weight: bold;
    }
  }

}
