.Statistic{

  .page-tab-box{

    .page-nav-tabs{
      border-color: #212529;
      border-width: 1px;

      .nav-item{
        padding: 0;
        cursor: pointer;
        .nav-link{
          color: #212529;
          font-weight: bold;
          padding: 10px 20px;
          border-width: 1px;
        }
        .nav-link:not(.active):hover {
          border-color: transparent;
        }
        .nav-link.active{
          border: 1px solid #212529;
          border-bottom: 1px solid transparent;
          background-color: white;
        }
      }
    }
  }

}